<template>
  <div></div>
</template>

<script>
export default {
  name: "adminAuth",
  created() {
    if(this.$route.params.key)
      this.$store.dispatch('creativeEdit/registerKey', this.$route.params.key)
  }
}
</script>

<style scoped>

</style>